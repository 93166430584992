<template>
  <div class="content">
    <navigation msg="萝卜猎手"></navigation>
    <div class="container">
      <div class="item">
        【关于我们】 萝卜猎手·牛人端是一个帮助企业与求职者精准匹配、寻找志同道合团队的平台的平台，目前覆盖国内20多万会员，致力于帮助企业发现人才技能、能力、爱好，匹配合适人选，降低用错人的风险，提高用人质量，帮助候选人找到更适合的企业和工作；平台上的HR们可为求职者/候选人寻找工作提供企业背书，帮助其提高面试、入职的成功率，找到合适的工作。
        
      </div>
      
      <div class="item">
        【简历推荐】 帮助求职者/候选人根据个人技能关键词，通过大数据匹配合适的企业，达到帮助其找到志同道合的团队，开启快乐工作之旅
      </div>

      <div class="item">
        【背调授权】
      企业看中的求职者/候选人，为了了解身份信息真伪情况，会发起背景调查，候选人仅需通过平台一键授权，即可提高面试或入职成功率
      </div>
      
      <div class="item">
        【点亮技能】
      为了向更多企业、老板、HR展示闪闪发光的您，您可以在牛人端创建各种各样、独一无二的技能，酒量高超，厨艺达人，跑酷高手……包含工作技能、生活技能、兴趣爱好在内的工作技能、生活技能、海量技能关键词，会更加有助于让企业老板、同事、领导快速发现你的个人优势，与你携手共事~
      </div>
      
      <div class="item">
         【职业生涯全纪录】
        每入职一家公司，被认可的技能将会永久被记录，方便未来你遇到的新同事能够更迅速的从你的技能中发现你的与众不同，帮你实现闪亮登场的特效
      </div>

     

是金子一定会发光！
快来萝卜猎手牛人端，让更多志同道合的小伙伴和公司发现闪闪发光的您！



    </div>
  </div>
</template>
<script>
import navigation from "../../components/navigation"
export default {
  components:{
    navigation
  }

}
</script>
<style scoped>
.container{
  font-size: 0.3rem;
  padding:0.4rem;
  
}
.content{
   position: fixed;
  height:100%;
  width:100%;
  background-color: #fff;
  top: 0;
  bottom:0;
  overflow-y:scroll;
  overflow-x:hidden;
}
.item{
  line-height: 0.5rem;
  margin-bottom:0.3rem;
}
</style>